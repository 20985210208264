import {Container, Grid, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "gatsby";
import React from "react";
import github from "../img/social/github.svg";
import linkedIn from "../img/social/linkedin.svg";
import medium from "../img/social/medium.svg";
import twitter from "../img/social/twitter.svg";
import xing from "../img/social/xing.svg";

const useStyles = makeStyles((theme: Theme) => ({
    footerContentTop: {
        background: theme.palette.secondary.light
    },
    footerContentCenter: {
        background: theme.palette.secondary.main
    },
    footerContentBottom: {
        background: theme.palette.secondary.dark
    },
    bottomContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: "1rem"
    },
    footerContent: {
        display: "flex",
        padding: "2rem 0px",
        transition: theme.transitions.create("padding"),
        [theme.breakpoints.only("xs")]: {
            padding: "0px 0px 2rem 0px"
        }
    },
    link: {
        textDecoration: "none",
        color: "white",
        fontSize: "1rem",
        lineHeight: "1.5rem",
        transition: theme.transitions.create(["color", "font-size", "text-align"]),
        "&:hover": {
            color: theme.palette.primary.light
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "0.9rem"
        },
        [theme.breakpoints.only("xs")]: {
            textAlign: "center"
        }
    },
    bottomLink: {
        textDecoration: "none",
        color: "white",
        fontSize: "0.9rem",
        margin: "0 0.25rem",
        transition: theme.transitions.create("color"),
        "&:hover": {
            color: theme.palette.primary.light
        }
    },
    info: {
        textDecoration: "none",
        color: "white",
        fontSize: "1rem",
        transition: theme.transitions.create(["text-align", "font-size"]),
        [theme.breakpoints.only("xs")]: {
            textAlign: "center"
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "0.9rem"
        }
    },
    contentWrapper: {
        display: "flex",
        justifyContent: "center",
    },
    contentHeadline: {
        color: "white",
        fontSize: "1.2rem",
        marginBottom: "0.25rem",
        fontWeight: 500,
        fontFamily: "neo-sans, sans-serif",
        transition: theme.transitions.create(["text-align", "margin"]),
        [theme.breakpoints.only("xs")]: {
            textAlign: "center",
            marginTop: "2rem"
        }
    },
    socialIcon: {
        height: "3rem",
        width: "3rem",
        borderRadius: "50%",
        margin: "0.5rem",
        transition: theme.transitions.create(["opacity", "width", "height"]),
        opacity: 0.75,
        "&:hover": {
            opacity: 1
        },
        [theme.breakpoints.only("xs")]: {
            height: "2.5rem",
            width: "2.5rem"
        }
    },
    socialList: {
        padding: "1rem",
        display: "flex",
        justifyContent: "center"
    },
    socialIconWrapper: {
        display: "flex"
    },
    contentBlock: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.only("xs")]: {
            width: "100%"
        }
    }
}));

const Footer: React.FC = () => {
    const classes = useStyles();

    return (
        <footer>
            <div className={classes.footerContentTop}>
                <Container>
                    <div className={classes.socialList}>
                        <a
                            className={classes.socialIconWrapper}
                            title="Twitter"
                            target="_blank"
                            href="https://twitter.com/miragon_io">
                            <img
                                className={classes.socialIcon}
                                src={twitter}
                                alt="Twitter"
                            />
                        </a>
                        <a
                            className={classes.socialIconWrapper}
                            title="XING"
                            target="_blank"
                            href="https://www.xing.com/companies/flowsquadgmbh">
                            <img
                                className={classes.socialIcon}
                                src={xing}
                                alt="XING"
                            />
                        </a>
                        <a
                            className={classes.socialIconWrapper}
                            title="LinkedIn"
                            target="_blank"
                            href="https://www.linkedin.com/company/miragon-io/">
                            <img
                                className={classes.socialIcon}
                                src={linkedIn}
                                alt="LinkedIn"
                            />
                        </a>
                        <a
                            className={classes.socialIconWrapper}
                            title="Medium"
                            target="_blank"
                            href="https://medium.com/@miragon/">
                            <img
                                className={classes.socialIcon}
                                src={medium}
                                alt="Medium"
                            />
                        </a>
                        <a
                            className={classes.socialIconWrapper}
                            title="Github"
                            target="_blank"
                            href="https://github.com/FlowSquad/">
                            <img
                                className={classes.socialIcon}
                                src={github}
                                alt="Github"
                            />
                        </a>
                    </div>
                </Container>
            </div>
            <div className={classes.footerContentCenter}>
                <Container>
                    <Grid
                        justify="center"
                        container
                        className={classes.footerContent}>
                        <Grid
                            xs={12}
                            sm={4}
                            md={3}
                            item
                            className={classes.contentWrapper}>
                            <div className={classes.contentBlock}>
                                <Typography className={classes.contentHeadline}>
                                    FlowCov
                                </Typography>
                                <Link
                                    to="/"
                                    className={classes.link}>
                                    <Typography className={classes.link}>
                                        Features
                                    </Typography>
                                </Link>
                                <Link
                                    to="https://docs.flowcov.io"
                                    className={classes.link}>
                                    <Typography className={classes.link}>
                                        Docs
                                    </Typography>
                                </Link>
                                <Link
                                    to="https://docs.flowcov.io/faq"
                                    className={classes.link}>
                                    <Typography className={classes.link}>
                                        FAQ
                                    </Typography>
                                </Link>
                                <a
                                    href="https://app.flowcov.io/"
                                    className={classes.link}>
                                    <Typography className={classes.link}>
                                        Login / Register
                                    </Typography>
                                </a>
                            </div>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={4}
                            md={3}
                            item
                            className={classes.contentWrapper}>
                            <div className={classes.contentBlock}>
                                <Typography className={classes.contentHeadline}>
                                    Miragon
                                </Typography>
                                <a
                                    href="https://www.miragon.io/"
                                    className={classes.link}>
                                    <Typography className={classes.link}>
                                        Home
                                    </Typography>
                                </a>
                                <a
                                    href="https://www.miragon.io/about"
                                    className={classes.link}>
                                    <Typography className={classes.link}>
                                        About Us
                                    </Typography>
                                </a>
                                <a
                                    href="https://www.miragon.io/blog"
                                    className={classes.link}>
                                    <Typography className={classes.link}>
                                        Blog
                                    </Typography>
                                </a>
                                <a
                                    href="https://www.miragon.io/career"
                                    className={classes.link}>
                                    <Typography className={classes.link}>
                                        Career
                                    </Typography>
                                </a>
                            </div>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={4}
                            md={3}
                            item
                            className={classes.contentWrapper}>
                            <div className={classes.contentBlock}>
                                <Typography className={classes.contentHeadline}>
                                    Location
                                </Typography>
                                <a
                                    className={classes.link}
                                    href="mailto:info@miragon.io">
                                    <Typography className={classes.link}>
                                        info@miragon.io
                                    </Typography>
                                </a>
                                <Typography
                                    className={classes.info}
                                    style={{fontWeight: 400}}>
                                    Miragon GmbH
                                </Typography>
                                <Typography
                                    className={classes.info}
                                    style={{fontWeight: 400}}>
                                    Böheimstraße 8
                                </Typography>
                                <Typography
                                    className={classes.info}
                                    style={{fontWeight: 400}}>
                                    86153 Augsburg
                                </Typography>
                                <Typography
                                    className={classes.info}
                                    style={{fontWeight: 400}}>
                                    Germany
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className={classes.footerContentBottom}>
                <Container className={classes.bottomContainer}>
                    <a
                        href="https://www.miragon.io/impressum"
                        target="_blank"
                        className={classes.bottomLink}>
                        <Typography className={classes.bottomLink}>
                            Imprint
                        </Typography>
                    </a>
                    <a
                        href="https://www.miragon.io/datenschutz"
                        target="_blank"
                        className={classes.bottomLink}>
                        <Typography className={classes.bottomLink}>
                            Privacy Policy
                        </Typography>
                    </a>
                </Container>
            </div>
        </footer>
    );
};
export default Footer;
