import {Theme, ThemeProvider} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import Helmet from "react-helmet";
import Footer from "../components/Footer";
import theme from "../utils/theme";
import Navigation from "./navigation/Navigation";

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        flexGrow: 1,
        marginTop: "60px",
        transition: theme.transitions.create("margin"),
        [theme.breakpoints.up("md")]: {
            marginTop: "75px"
        }
    }
}));

const Layout: React.FC = ({children}) => {
    const classes = useStyles();
    return (
        <ThemeProvider theme={theme}>
            <div style={{display: "flex", flexDirection: "column", minHeight: "calc(100vh)"}}>
                <Helmet>
                    <html lang="en"/>
                    <link
                        rel="apple-touch-icon"
                        sizes="180x180"
                        href="/img/apple-touch-icon.png"
                    />
                    <link
                        rel="icon"
                        type="image/png"
                        href="/img/favicon-32x32.png"
                        sizes="32x32"
                    />
                    <link
                        rel="icon"
                        type="image/png"
                        href="/img/favicon-16x16.png"
                        sizes="16x16"
                    />
                </Helmet>
                <Navigation/>
                <div className={classes.content}>
                    {children}
                </div>
                <Footer/>
            </div>
        </ThemeProvider>
    )
};
export default Layout;
