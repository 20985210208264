import {AppBar, Button, Container, Toolbar} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Theme} from "@material-ui/core/styles/createMuiTheme";
import {Link} from "gatsby";
import React from "react";
import NavigationItem from "./NavigationItem";

const navigationItems: NavItem[] = [
    {
        link: "https://docs.flowcov.io",
        title: "Docs"
    },
    {
        link: "https://docs.flowcov.io/faq",
        title: "FAQ"
    }
];

const useStyles = makeStyles((theme: Theme) => ({
    appBar: {
        background: "#26b44b",
        zIndex: 1400,
        top: 0,
        width: "100%",
        height: "75px",
        minHeight: "75px",
        [theme.breakpoints.down("sm")]: {
            height: "60px",
            minHeight: "60px",
            padding: "0px",
        }
    },
    headerItems: {
        display: "flex",
        justifyItems: "center",
        flexDirection: "row",
        width: "100%",
        height: "100%",
        alignItems: "center"
    },
    navigationItems: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        transition: theme.transitions.create("margin"),
        [theme.breakpoints.up("lg")]: {
            fontSize: "1.25rem"
        }
    },
    logo: {
        textDecoration: "none",
        display: "flex",
        [theme.breakpoints.only("xs")]: {
            display: "none"
        }
    },
    toolbar: {
        height: "100%"
    },
    logoImg: {
        width: "200px"
    },
    login: {
        marginLeft: "2rem",
        textTransform: "none",
        color: "white",
        borderColor: "white",
        fontSize: "1rem",
        whiteSpace: "nowrap"
    },
    buttonLink: {
        textDecoration: "none"
    },
    spacer: {
        flexGrow: 1
    }
}));

export interface NavItem {
    link: string;
    title: string;
    external?: boolean;
}

const Navigation: React.FC = () => {
    const classes = useStyles();

    let items = navigationItems.map(item => (
        <NavigationItem
            external={item.external}
            key={item.link}
            link={item.link}
            title={item.title}>
            {item.title}
        </NavigationItem>
    ));
    return (
        <>
            <AppBar
                elevation={0}
                className={classes.appBar}
                position="fixed">
                <Toolbar className={classes.toolbar}>
                    <Container className={classes.headerItems}>
                        <h1>
                            <Link to="/" className={classes.logo}>
                                <img className={classes.logoImg} src="/img/logo.svg" />
                            </Link>
                        </h1>
                        <span className={classes.spacer} />
                        <nav className={classes.navigationItems}>
                            {items}
                        </nav>
                        <span className={classes.spacer} />
                        <a
                            className={classes.buttonLink}
                            href="https://app.flowcov.io/">
                            <Button
                                disableElevation
                                className={classes.login}
                                variant="outlined">
                                Login / Register
                            </Button>
                        </a>
                    </Container>
                </Toolbar>
            </AppBar>
        </>
    );
}

export default Navigation;
