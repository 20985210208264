import {createMuiTheme} from "@material-ui/core/styles";

const theme = createMuiTheme({
    palette: {
        primary: {
            light: "#32d35c",
            main: "#26b44b",
            dark: "#3d9a4c",
            contrastText: "#fff"
        },
        secondary: {
            light: "#455a64",
            main: "#37474f",
            dark: "#263238",
            contrastText: "#fff"
        },
        text: {
            primary: "rgba(0, 0, 0, 0.87)",
            secondary: "rgba(0, 0, 0, 0.6)",
            hint: "rgba(0, 0, 0, 0.38)",
            disabled: "rgba(0, 0, 0, 0.38)"
        }
    },
    typography: {
        fontFamily: "Helvetica, Arial, sans-serif",
        h1: {
            fontSize: "2.5rem",
            fontWeight: "bold",
            lineHeight: "4rem"
        },
        h2: {
            fontSize: "2.0rem",
            fontWeight: "bold",
            lineHeight: "3rem"
        },
        h3: {
            fontSize: "1.5rem",
            fontWeight: "bold",
            lineHeight: "3rem"

        },
        h4: {
            fontSize: "1.5rem",
            lineHeight: "3rem"
        },
        body1: {
            fontFamily: "Lucida Grande, Lucida Sans Unicode, Lucida Sans, Geneva, Verdana, sans-serif",
            fontSize: "1.2rem",
            letterSpacing: "-0.03rem"
        },
        body2: {
            fontFamily: "Lucida Grande, Lucida Sans Unicode, Lucida Sans, Geneva, Verdana, sans-serif",
            letterSpacing: "-0.03rem"
        }
    }
});

export default theme;
