import {Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {globalHistory} from "@reach/router";
import clsx from "clsx";
import {Link} from "gatsby";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
    h6: {
        color: "rgba(255,255,255,0.87)",
        fontSize: "1.1rem",
        fontFamily: "Lucida Grande, Lucida Sans Unicode, Lucida Sans, Geneva, Verdana, sans-serif",
        padding: "0.25rem 1rem",
        transition: theme.transitions.create(["padding", "font-size"]),
        [theme.breakpoints.up("lg")]: {
            fontSize: "1.25rem",
            padding: "0.25rem 1.25rem"
        }
    },
    link: {
        display: "flex",
        alignItems: "center",
        height: "100%",
        textDecoration: "none",
        whiteSpace: "nowrap",
        color: "rgba(255,255,255,0.87)",
        transition: theme.transitions.create(["color", "background-color"]),
        "&:hover": {
            color: "#FFFFFF",
            backgroundColor: "rgba(255,255,255,0.1)"
        }
    },
    activeLink: {
        backgroundColor: "rgba(255,255,255,0.1)"
    }
}));

interface Props {
    link: string;
    children?: React.ReactNode;
    title: string;
    className?: string;
    external?: boolean;
}

const NavigationItem: React.FC<Props> = props => {
    const classes = useStyles();
    let active: boolean;

    if (props.link === "/") {
        active = globalHistory.location.pathname === "/";
    } else {
        active = globalHistory.location.pathname.startsWith(props.link);
    }

    if (props.external) {
        return (
            <a
                className={clsx(classes.link, active && classes.activeLink, props.className)}
                href={props.link}>
                <Typography
                    variant="h6"
                    className={classes.h6}>{props.children}</Typography>
            </a>
        );
    }

    return (
        <Link
            className={clsx(classes.link, active && classes.activeLink, props.className)}
            to={props.link}>
            <Typography
                variant="h6"
                className={classes.h6}>{props.children}</Typography>
        </Link>
    );
};

export default NavigationItem;
